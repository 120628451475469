.testlibrarycards_main {
  // margin: 1.5rem 0rem;
  padding: 1rem 1.5rem;
  padding-block-end: 2.5rem;

  .testlibrarycards_main_grid {
    display: grid;
    gap: 1.5rem;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 20rem));
    grid-auto-rows: auto;
    // grid-template-columns: repeat(3, minmax(0, 1fr));
    justify-content: flex-start;

    .testlibrarycard {
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      background: hsl(0deg 0% 100% / 99%);
      transition: box-shadow 0.3s ease;
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
      user-select: none;

      .testlibrarycard_header {
        color: var(--color-text-primary);
        font-size: 1rem;
        white-space: pre-wrap;
        word-break: break-all;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .testlibrarycard_subheader {
        font-size: 0.875rem;
        color: var(--color-text-default-90);
      }

      .testlibrarycard_time_ques {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        gap: 0.5rem;
        color: var(--color-text-default-75);
        font-size: 0.75rem;
        margin-top: auto;
      }

      .testlibrarycard_footer_buttons {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        cursor: pointer;
        // max-width: max-content;
        justify-content: flex-end;
        border-top: 1px solid #eee;
        padding-top: 0.5rem;
      }
    }

    .testlibrarycard_ques,
    .testlibrarycard_time {
      border: 1px solid #ddd;
      padding: 0.15rem 0.25rem;
      border-radius: 2px;
      background: hsl(0deg 0% 86.67% / 23.14%);
    }

    .beginner {
      border-color: #6eff7a;
      /* Light green for Easy */
      color: #000000;
      /* Black text for contrast */
    }

    .intermediate {
      border-color: #ffdd57;
      /* Yellow for Medium */
      color: #000000;
      /* Black text for contrast */
    }

    .scholar {
      border-color: #ff6464;
      /* Red for Hard */
      color: #ffffff;
      /* White text for contrast */
    }

    .testlibrarycard:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }
}

.label_no {
  margin-top: 5rem;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none;
}
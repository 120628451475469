.dashboard_banner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  // background-color: #e6effc;
  padding: 0.75rem 0rem;


  .banner_profile_avatar_section {
    display: flex;
    align-items: flex-end;
    gap: 0.5rem;
    // border: thin solid #e6effc;

    .avatar_section {
      align-items: center;
      background: #e6effc;
      border-radius: 4px;
      color: #2c7be5;
      display: inline-flex;
      flex-direction: column;
      font-size: 3rem;
      font-weight: 600;
      justify-content: center;
      min-height: 1.75rem;
      min-width: 4.75rem;
      padding: 0.25rem;
    }

    .profile_name {
      display: flex;
      flex-direction: column;
      justify-items: flex-end;

      .initials {
        font-size: 1rem;
        font-weight: 400;
      }

      .name {
        font-size: 2rem;
        font-weight: 600;
        color: var(--color-primary);
      }
    }
  }

  .banner_show_latest {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 0.5rem;

    .uplatest {
      display: flex;
      gap: 0.25rem;
      border: thin #ccc solid;
      padding: 0.3rem;

      .uplatest_details {
        display: flex;
        flex-direction: column;

        .no {
          font-weight: 500;
          font-size: 0.9rem;
        }

        header {
          font-weight: 500;
          border-bottom: thin solid #ccc;
          font-size: 1.025rem;
        }

        .details {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          font-weight: 500;

          p {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
}
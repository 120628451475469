.pyui_footer {
  // box-shadow: 0 -0.25rem 1rem rgb(0 0 0 / 15%);
  padding: 0.5rem 1rem;
  margin-block-start: auto;
  background: var(--color-bg-light);
  font-size: 0.75rem;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgb(204 204 204 / 44%);
  color: #666;
  gap: 0.5rem;
}
.puyi_powered {
  font-style: italic;
}

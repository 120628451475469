.pyui_filters {
  display: flex;
  align-items: flex-end;
  gap: 1rem;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 0.7);
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  font-size: 0.875rem;

  .pyui_filters_league,
  .pyui_filters_tracks {
    display: flex;
    gap: 0.25rem;
    flex-direction: column;
  }
}

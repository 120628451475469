// Question form
.pyui_questions-form-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.form-render {
  flex-grow: 1;
  flex-basis: 100%;
  overflow: auto;
}

// Form
.dynamic-form {
  max-width: 620px;

  .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 0 0.25rem;
    margin-bottom: 1rem;
  }

  .form-label {
    font-size: 0.875rem;
  }

  select {
    margin-top: 5px;
    line-height: 1.5em;
  }

  .form-group button {
    padding: 0.5em;
  }

  .form-group-radio {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;
    // flex-direction: row;

    .form-group-input-label {
      display: flex;
      align-items: flex-start;
    }

    label {
      margin-inline-end: 1rem;
      font-size: 0.875rem;
    }

    input {
      margin-inline-end: 0.25rem;
      margin-top: 0.25rem;
    }
  }

  .form-group-checkbox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;

    .form-group-input-label {
      display: flex;
      align-items: flex-start;
    }

    label {
      margin-inline-end: 1rem;
      font-size: 0.875rem;
    }

    input {
      margin-inline-end: 0.25rem;
      margin-top: 0.25rem;
    }
  }

  .form-title {
    text-align: left;
  }
}

.form-actions {
  // display: flex;
  // justify-content: end;
  // align-items: center;
  // border-top: 2px solid #ccc;
  // border-bottom: 2px solid #ccc;
  // background: hsl(202.7deg 45.68% 84.12% / 98.04%);
  padding: 0rem 0.25rem 0.5rem 0.25rem;
  // padding-bottom: 0.5rem;
  gap: 0.5rem;


  .form-actions-title {
    padding: 0.25rem;
    // border-bottom: 1px solid #ccc;
  }

  .form-actions-btn-title {
    display: flex;
    justify-content: space-between;
  }

  .btncompleted {
    display: flex;
    justify-content: end;
    gap: 1rem;
  }

  .btn-checkanswers {
    display: flex;
    justify-content: end;
  }
}


// Modal

.pyui_modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  &-content {
    background-color: #fff;
    box-shadow: 0 0.1875rem 0.1875rem 0 rgba(14, 34, 56, 0.07);
    margin: 10rem auto;
    width: 26rem;
    max-width: 100%;
    min-height: 130px;
    animation-name: modalopen;
    animation-duration: 0.5s;
    z-index: 2;
    border-radius: 5px;
  }

  // header
  &-header {
    background-color: #4199ce;
    padding: 0.5rem 1rem;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    border-top: 2px solid hsl(202.55deg 66.36% 81.71% / 46%);
    display: flex;
    justify-content: space-between;

    &-title {
      color: #fff;
      font-size: 1.2rem;
      font-weight: 400;

      i {
        font-size: 1rem;
        margin-right: 0.6rem;
        color: #fff;
      }
    }

    .closebtn {
      color: #fff;
      cursor: pointer;
    }
  }

  // modal body
  &-body {
    padding: 0.25rem 1.25rem 0.5rem 1.25rem;

    &-container {
      padding-bottom: 1rem;
      font-size: 0.875rem;

      .correct-answers {
        font-size: 1.15rem;

        >span {
          color: #aaa;
        }
      }

      .correct-answers-count {
        font-size: 1.75rem;

        &.pass {
          color: hsl(155deg 100% 25%);
        }

        &.fail {
          color: hsl(349deg 61% 45%);
        }
      }

      .total-questions {
        font-size: 1.75rem;
        color: var(--color-text-default);
      }

      .min-correct-answers {
        margin-block-start: 0.5rem;
      }
    }
  }
}

#modalloader-spinner p {
  /* background-color: #f4f4f4; */
  /* margin: 20% auto; */
  /* width: 30%; */
  /* height: 20px; */
  padding-top: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalUserScore {
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1rem;
  gap: 1rem;

  .count-table-container {
    .count-table {
      height: 10rem;

      // .attempted {
      //   color: #fff;
      //   background-color: rgba($color: #228b22, $alpha: 0.5)
      // }

      // .notattempted {
      //   color: #fff;
      //   background-color: rgba($color: #922724, $alpha: 0.5);
      // }


    }
  }

  // font-weight: 500;
}

.modalUserScore i {
  font-size: 20px;
  margin-right: 10px;
  color: inherit;
  /* border-color: #8a2be2; */
}

.modal-footer {
  padding: 5px 0px;
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: right;
}

@keyframes modalopen {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
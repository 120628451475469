.shuffle-column-wrapper {
  display: flex;
  gap: 0.35rem;
  padding: 0.35rem 0.15rem;
  min-height: 20rem;
}

.shuffle-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  // max-width: 25rem;
  padding: 0.5rem;
  // height: 100%;
  background-color: #ffffff;
  border: 2px dashed #d3d3d3;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, background-color 0.3s ease;

  &.drag-over {
    border-color: #007bff;
    background-color: #e7f1ff;
  }
}

.shuffle-container > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 0.5rem;
}

.sortable-item {
  // padding: 10px 15px;
  // border: 1px solid #dcdcdc;
  // border-radius: 4px;
  // background-color: #ffffff;
  // color: #333333;
  // font-size: 14px;
  // font-weight: 500;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  // transition: transform 0.2s ease, box-shadow 0.2s ease;
  // cursor: grab;
  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  }

  &.dragging {
    transform: scale(1.05);
    background-color: #f8f9fa;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
    border-color: #007bff;
    cursor: grabbing;
  }
}

.drag-overlay {
  pointer-events: none;

  .sortable-item {
    opacity: 0.8;
    transform: scale(1.1);
    border-color: #007bff;
    background-color: #f0f8ff;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
  }
}

// // column
// .shuffle-column-wrapper {
//   display: flex;
//   flex-direction: row;
// }
// .shuffle-container {
//   background: "#dadada";
//   padding: 10;
//   margin: 10;
//   flex: 1;
// }

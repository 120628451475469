.login-page {
  padding: 0px;
  padding-bottom: 2rem;
  margin: 0px;
  width: 100vw;
  max-width: 100%;
  height: 100%;
  // max-height: 100%;
  // transform: translateY(30%);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  //  background-color: azure;
  justify-content: center;
  background: linear-gradient(180deg, #dfe9f3, #fff);
}

.login-form {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 25rem;
  height: max-content;
  gap: 1rem;
  // max-height: 31rem;
  // overflow-y: auto;
  padding: 2rem;
  align-items: center;
  background: #fff;
  // /* justify-content: center;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 10px;

  .reset-footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .tabs {
    background: #eee;

    > li.active {
      box-shadow: 0px -2px 2px #ccc;
    }
  }
}

.login-form-input {
  max-width: 90%;
  width: 16rem;
  height: 2rem;
}

.msg {
  font-weight: 600;
}

.msg-div {
  position: absolute;
  top: 1rem;
  background-color: rgb(75, 243, 187);
  padding: 0.25rem 0.75rem;
  border-radius: 10px;
}

.login-disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.login-input-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 1rem;

  > div {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;

    > label {
      font-size: 0.875rem;
    }
  }
}
.text-msg {
  font-size: 0.75rem !important;
  font-weight: 400;
  line-height: 1.667;
  letter-spacing: 0.0333333333em !important;
  font-family: "Roboto", sans-serif;
  text-transform: none !important;
}
.div-name-field {
  display: flex;
  gap: 1rem;
}
.otp-wrapper {
  width: 100%;
  display: inline;
  gap: 1rem;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .div-name-field {
    display: flex;
    flex-direction: column;
    // gap: 1rem;
  }
}

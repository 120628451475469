.table-container table {
  width: 100%;
  border-collapse: collapse;
  font-family: Arial, sans-serif;
  /* Specify your preferred font */
  font-size: 0.75rem;
  /* Adjust font size as needed */
  // display: block;
  //   /* Allow table to overflow container */
  // margin-bottom: -20px;
  border-bottom: thin slid lightgray;
}

.table-container table thead th {
  position: sticky;
  top: -1px;
  /* Fix header to top of scrollable area */
  // background-color: #fff;
  // background-color: #68acd5;
  /* Change background for fixed header */
  z-index: 1;
  /* Set stacking context for fixed header */
  padding: 0.5rem;
  // font-size: 0.85rem;
  text-align: start;
  /* Adjust alignment as needed */
  background-color: #68acd5;
  // background-color: #e0e0e0;
  /* Light gray background */
  color: #fff;
  /* Dark text for contrast */
  border: 1px solid #ddd;
  /* Light border */
  font-weight: 500;
  font-size: 0.9rem;
  letter-spacing: 1px;
}

.table-container table tbody td {
  border: 1px solid #ddd;
  text-align: start;
}
.table-container table thead th.alignCenter,
.table-container table tbody td.alignCenter {
  text-align: center;
}

.table-container tbody tr:nth-child(even) td {
  background-color: #f5f5f5;
}

.table-container tbody tr:hover td {
  background-color: hsl(200.73deg 94.83% 77.25% / 19%);
  transition: all 0.2s ease-in-out;
}

.table-container {
  .pinned-rows-table {
    /* Styles for pinned rows container */
    position: sticky;
    top: 1;
    z-index: 2;
    /* Ensure pinned rows stay on top */
    background-color: #fff;
    /* Optional background color */
    padding: 0.5rem;
  }
}

// .table-container table tbody tr:nth-child(odd) {
//   // background-color: #f5f5f5;
//   background-color: hsl(200.73deg 94.83% 77.25% / 19%);
//   /* Light gray for odd rows */
// }

// .table-container table tbody tr:hover td {
//   // background-color: #f0f0f0;
//   background-color: hsl(200.73deg 94.83% 77.25% / 19%);
//   transition: all 0.2s ease-in-out;
//   /* Lighter gray on hover */
//   // cursor: pointer;
//   /* Display pointer for clickable elements (optional) */
// }

/* Optional: Styling for a fixed table header */

.table-container {
  overflow-y: scroll;
  /* Enable scrolling for large tables */
  height: 25rem;
  /* Adjust height as needed */
  // border: thin solid lightgray;
}

// .table-container table {
//   display: block;
//   /* Allow table to overflow container */
//   margin-bottom: -20px;
//   /* Remove bottom border for seamless scrolling */
// }

// .table-container table thead th {
//   position: sticky;
//   top: 0;
//   /* Fix header to top of scrollable area */
//   background-color: #fff;
//   /* Change background for fixed header */
//   z-index: 1;
//   /* Set stacking context for fixed header */
// }

.pyyi_table-zerostate {
  margin: 2rem;
  font-size: 1.25rem;
  color: #cacaca;
  font-weight: 400;
  text-align: center;
}

.table-scroll::-webkit-scrollbar {
  width: 0.3rem;
}

.table-scroll::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: #eee;
}

.table-scroll::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 12px;
  // border: 4px solid #fff;
}

.table-scroll::-webkit-scrollbar-button {
  display: none;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.875rem;
}
table thead th {
  border: 1px solid #868686;
  background: #68acd5; // #4199ce;
  color: #fff;
  position: sticky;
  top: -1px;
  font-weight: 600;
  padding: 0.5rem;
}
table tbody td {
  padding: 0.5rem;
  border: 1px solid hsl(0deg 1.26% 53.14% / 40%);
  border-collapse: collapse;
  text-align: center;
  background: #fff;
  transition: all 0.2s ease-in-out;
}
table tbody tr:nth-child(even) td {
  background-color: #f5f5f5;
}
table tbody tr:hover td {
  background-color: hsl(200.73deg 94.83% 77.25% / 19%);
  transition: all 0.2s ease-in-out;
}

.pyyi_table-zerostate {
  margin: 2rem;
  font-size: 1.25rem;
  color: #cacaca;
  font-weight: 400;
  text-align: center;
}

.table-scroll::-webkit-scrollbar {
  width: 0.3rem;
}

.table-scroll::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: #eee;
}

.table-scroll::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 12px;
  // border: 4px solid #fff;
}

.table-scroll::-webkit-scrollbar-button {
  display: none;
}

 .datatable_display_data {
   overflow-y: scroll;
  //  max-height: 12rem;
 }

 .schedule_datatable_footer {
   display: flex;
   align-items: center;
   gap: 0.75rem;
   justify-content: flex-end;
 }
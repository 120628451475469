/* @import url("https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css"); */

.form {
  /* height: 55vh; */
  /* height: 410px; */
  /* width: 700px; */
  /* background-color: blue; */
  /* overflow-y: scroll; */
}

/* .dynamic-form {

  margin-bottom: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* margin: 0px 2px; 
  max-width: 620px;
  /* overflow: auto; 
}

.dynamic-form .form-group {
  /* margin-right: 20px;
  margin-left: 30px; 
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.25rem;
  margin-bottom: 1rem;
}

.dynamic-form .form-label {
  text-align: left;
 
  font-size: 0.875rem;
  white-space: pre-wrap;
}

.dynamic-form .form-input {
  margin-top: 5px;
  line-height: 1.5em;
 
  border: 1px solid rgba(145, 142, 142, 0.37);
  border-radius: 5px;
  padding: 5px;
}

.dynamic-form .form-input:focus {
  outline: 1px solid #8ed6fc;
  border-color: #8ed6fc;
}

.dynamic-form select {
  margin-top: 5px;
  line-height: 1.5em;
}

.dynamic-form .form-group button {
  padding: 0.5em;
}

.dynamic-form .form-group-radio {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  /* justify-content: center; 
  /* background-color:red; 
}

.form-group-radio label {
  margin-right: 15px;
  font-size: 15px;
}

.form-group-radio input {
  margin-right: 5px;
}

.dynamic-form .form-group-checkbox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.form-group-checkbox label {
  margin-right: 15px;
  font-size: 15px;
}

.form-group-checkbox input {
  margin-right: 5px;
}

.form-title {
  text-align: left;
}

.form-actions {
  display: flex;
  justify-content: end;
  align-items: center;
  border-top: 1px solid black;
}

.form-actions .action-buttons {
  margin: 10px 20px;
} */

/* .form-actions #button-1 {
  padding: 5px 10px;
  border-radius: 4px;
  border: 0;
  font-weight: 600; */
/* width: 80px; */
/* line-height: 1.5em; */
/* background-color: #8a2be2;
  color: white;
  border-radius: 4px; */
/* border: 5px solid #8a2be2; */
/* margin-right: 10px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  text-transform: capitalize;
  font-family: "Poppins";
  font-weight: 600; */
/* } */

/* .form-actions #lable-1 { */
/* width: 150px; */
/* padding: 5px 10px; */
/* line-height: 1.5em; */
/* background-color: #8a2be2; */
/* color: white;
  border-radius: 4px;
  border: 0;
  margin: 10px;
  font-size: 14px;
  margin-right: 20px;
  text-transform: capitalize;
  font-family: "Poppins", sans-serif;
  font-weight: 600; */
/* } */

/* .form-actions #lable-1.disabled {
  opacity: .7;
  background-color: #ccc;
  cursor: not-allowed;
} */

#lable-1 i {
  padding-right: 15px;
}

.formloader-spinner {
  text-align: center;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  /* background-color: yellowgreen; */
  /* width: 50px; */
  /* z-index: 9999; */
}

.formloader-spinner p {
  display: flex;
  align-items: center;
  padding: 3px 5px;
  background-color: rgba(100, 100, 111, 0.02);
}

.modalloader-spinner {
  background-color: #f4f4f4;
  margin: 20% auto;
  width: 30%;
  /* animation-name: modalopen; */
  /* animation-duration: 0.5s; */
  z-index: 2;
}
/* 
#mark-btn-1 {
 
  font-weight: 500;
  background-color: #8a2be2;
  font-size: 12px;
}

#cancel-btn-1 {
  margin-left: 10px;
  padding: 5px 7px;
  border: 0;
  color: #fff;
  cursor: pointer;
  
  font-weight: 500;
  background-color: #8a2be2;
  font-size: 12px;
}

.cancel-btn-2 {
  margin-left: 10px;
  
  padding: 5px 7px;
  border: 0;
  color: #fff;
  cursor: pointer;
  
  font-weight: 500;
  background-color: #8a2be2;
  font-size: 12px;
}

#mark-btn-1:active,
#cancel-btn-2:active,
#cancel-btn-1:active {
  transform: translateY(3px);
}



.closebtn {
  color: #fff;
  font-size: 20px;
  float: right;
  
}

.closebtn:hover,
.closebtn:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
} */

.marked-completed-msg {
  display: flex;
  margin-top: 10px;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}

.marked-completed-msg i {
  border-radius: 50%;
  border: 1px solid green;
  color: green;
  padding: 5px;
  margin-right: 10px;
}

.modal-footer-msg {
  display: flex;
  justify-content: right;
  align-items: flex-end;
}

/* .primary-button {
  border: none;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  background-color: var(--color-secondary);
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.4s ease-in-out;
}

.primary-button:hover {
  background-color: rgb(62 64 149 / 78%);
  transition: all 0.2s ease-in-out;
}

.completed-button {
  border: none;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  background-color: #059862;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.4s ease-in-out;
  margin-left: 0.5rem;
}

.completed-button:hover {
  background-color: #4caf50;
  transition: all 0.2s ease-in-out;
} */

@keyframes modalopen {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.celebDiv {
  z-index: 10;
}
.message-container {
  position: fixed;
  top: 32%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* animation: fall 4.5s forwards; */
}

@keyframes fall {
  from {
    top: 0%;
    opacity: 1;
  }
  to {
    top: 50%;
    opacity: 1;
  }
}

.message {
  font-size: 1rem;
  text-align: center;
  background-color: white;
  padding: 1em;
  border-radius: 0.5em;
  box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.3);
}

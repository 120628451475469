// .meditor {}

.editor-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}


.loader-spinner {
  text-align: center;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;

  z-index: 9999;
}

.image-div {
  height: 400px;
  width: 100%;
  overflow-y: auto;
  overflow-x: auto;
  scroll-snap-type: x mandatory;

  display: flex;

  flex-direction: column;

  justify-content: space-between;
}

.image-div img {
  aspect-ratio: 4/4;
  object-fit: contain;
  scroll-snap-align: center;
  // scroll-snap-align: ;

  // image-resolution:10dpi snap ;

  mix-blend-mode: darken;
}

.image-action-btn-div {
  display: flex;
  float: right;
  // column-gap: 1rem;
}

.image-action-btn-div>button {
  cursor: pointer;
}

.sliderBtn {
  justify-self: flex-end;
}

/*
.image-div::-webkit-scrollbar {
  // width: 3rem;
}
.image-div::-webkit-scrollbar-track {
  border-radius: 12px;
  background-color: #df1e1e;
}
.image-div::-webkit-scrollbar-button {
  display: none;
}
.image-div::-webkit-scrollbar-thumb {
  background-color: rgb(142, 142, 142);
}
*/

.full-screen {
  display: flex;
  justify-content: center;
  position: absolute;
  margin: 0;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

// .full-screen-div>button {
// z-index: 120;
// }

// .full-image-div{
//   margin: auto;
//   width: 100%;
//   height: 90%;

//   background-color: rgba(0, 0, 0, 0.5);
// }
// .full-screen{
//   background-color: #f4f4f4;
//   margin: 5% auto;
//   width: 90%;
//   min-height: 650px;
//   animation-name: modalopen;
//   animation-duration: 0.5s;

// }
.full-screen-img-div {
  height: 90%;
  width: 99%;

  overflow-y: scroll;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  background-color: rgb(238, 238, 238);
  display: flex;
  // margin: auto;
  position: absolute;
  margin: 0;
  // left: 2rem;
  top: 4rem;

  flex-direction: column;

  justify-content: space-between;
}

.full-screen-img {
  // margin: auto;
  width: 95%;

  // bottom: .5rem;
  // aspect-ratio: 5/4;
  object-fit: contain;
  image-resolution: 100dpi snap;

  mix-blend-mode: darken;

  height: 800px;
  // min-height: inherit;
  // scroll-snap-align: center;
  box-sizing: content-box;
  box-shadow: #bcbcbc;
}

.full-screen-action-btn-div {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 5rem;
  right: 2rem;
  z-index: 1;
  // float: right;
}

.full-screen-action-btn-div>button {
  cursor: pointer;
}

.loader-spinner p {
  display: flex;
  align-items: center;
  padding: 3px 5px;
  background-color: rgba(100, 100, 111, 0.02);
}

// .information-menu {
//   display: grid;
//   grid-template-columns: 1fr auto;
//   align-items: center;
// }

// .editor-title {
//   font-weight: 600;
//   font-size: 1.12rem;
//   color: #6699cc;
// }

.pre-next {
  justify-self: center;
}

// .primary-button {
//   border: none;
//   padding: 0.25rem 0.5rem;
//   border-radius: 3px;
//   background-color: var(--color-secondary);
//   color: white;
//   cursor: pointer;
//   font-size: 0.875rem;
//   font-weight: 500;
//   transition: all 0.4s ease-in-out;
//   margin-left: 0.875rem;
// }

// .primary-button:disabled {
//   background-color: #ccc;
//   pointer-events: none;
// }

// .primary-button:disabled:hover {
//   background-color: #ccc;
// }

// .primary-button:hover {
//   background-color: rgb(62 64 149 / 78%);
//   transition: all 0.2s ease-in-out;
// }

// .button {
//   padding: 2px;
//   border: 0;
//   margin: 0px 5px;
//   font-size: 1rem;
//   background-color: transparent;
//   cursor: pointer;
//   /* border-radius: 50px; */
// }

// .button:hover {
//   border-bottom: 1px solid black;
// }

// .disabled {
//   // padding: 5px;
//   // border: 0;
//   // margin: 0px 5px;
//   // font-size: 1rem;
//   // background-color: transparent;
//   // cursor: not-allowed;
//   // border-radius: 50px;
//   // color: lightgray;
//   pointer-events: none;
//   opacity: 0.4;
// }

.instructions {
  justify-self: end;
}

.instructions button {
  padding: 5px 7px;
  font-size: 1rem;
  border: 0;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  color: white;
  background-color: #8a2be2;
  justify-self: right;
}

.instructions .disabled-1 {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #ccc;
}

// .instructions button:hover {
//   background-color: #8a2be2;
// }

// .editor-questions {
// display: grid;
// grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
// gap: 1rem;
// background: rgb(204 204 204 / 25%);
/* width: 100%; */
// margin: 10px 30px;
/* height: 440px; */
/* display: flex; */
/* align-items: center; */
/* justify-content: space-around; */
// }

/* without debug link */
// .editor {
// width: 630px;
// }

.editor-lable {
  padding: 5px 5px 5px 15px;
  text-transform: capitalize;
  font-weight: 600;
  // border: 1px solid black;
  // border-top-left-radius: 10px;
  // border-top-right-radius: 10px;
}

.to-debugger {
  padding: 0.5rem;
  display: flex;
  // justify-content: space-between;
  gap: 0.5rem;
}

.to-debugger-eval-quiz {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;

  .to-debugger-eval-btn-limit {
    display: flex;
    gap: 1rem;
    // flex-direction: column;
    // align-items: center;

    button {
      width: fit-content;
    }

    .eval-run-btn-warning {
      border: none;
      // padding-top: inherit;
      // padding-left: 4rem;
      width: 20rem;
      border-radius: 3px;
      display: flex;
      gap: 0.25rem;
      // float: right;
      color: white;
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: 500;
      transition: all 0.4s ease-in-out;
      // background-color: hsl(51, 100%, 92%);
      color: var(--color-primary);
      // border-color: var(--color-primary);
    }
  }
}

/* with debug link */
// .editor-debug {
// width: 630px;
// }

// .questions {
// height: 485px;
// border-top-left-radius: 10px;
// border-top-right-radius: 10px;
// display: flex;
// flex-direction: column;
// }

// .question-lable {
// padding: 5px 5px 5px 15px;
// text-transform: capitalize;
// font-weight: 600;
// }

/*
.question-form {
  flex-grow: 1;
  flex-basis: 100%;
  overflow: auto;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.form-render {
  flex-grow: 1;
  flex-basis: 100%;
  overflow: auto;
}*/

.nav-debug-tabs {
  padding: 0.5rem;
}

.nav-debug-list {
  display: flex;
  gap: 0.5rem;
  font-size: 0.875rem;
  border-bottom: 1px solid #bcbcbc;

  >.tab {
    padding: 0.25rem 0.5rem;
    cursor: default;
    border-bottom: 2px solid transparent;
  }

  >.tab.active {
    font-weight: 600;
    border-bottom-color: hsl(205deg 100% 55% / 75%);
  }
}

.tab-description {
  padding: 0.5rem;
  font-size: 0.875rem;
}

.debug-textarea {
  width: 100%;
  max-height: 20rem;
  min-height: 10rem;
  background: hsl(0deg 0% 100% / 40%);
  padding: 0.25rem;
  box-sizing: border-box;
  border-color: #c9c9c9;
  resize: vertical;
  outline: none;
}

.result-table {
  width: 20rem;
  font-size: 0.87rem;
  height: auto;
  border: 1px solid #ccc;
  border-collapse: collapse;
  color: var(--color-text-default);

  th,
  td {
    border: 1px solid #ccc;
    text-align: center;
    padding: 0.25rem;
  }
}

.main-link-debug,
.mark-button-debug {
  border: none;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  display: flex;
  gap: 0.25rem;
  background-color: var(--color-secondary);
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.4s ease-in-out;
  height: min-content;
}

.main-link-debug {
  background-color: var(--color-accent);
  display: flex;
  gap: 0;
  height: min-content;
}

.testcase-title {
  display: flex;
  gap: 0.5rem;
  margin-block-end: 0.25rem;

  >span {
    font-weight: 400;
  }
}

.pyui_editor-infobar {
  display: grid;
  grid-template-columns: 33% 67%;
  // gap: 1rem;
  align-items: center;

  .editor-title {
    font-weight: 400;
    // font-size: 1.12rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    &-pre {
      font-size: 0.75rem;
      color: hsl(214.38deg, 78.06%, 53.53%);
    }
  }

  .editor-navigation {
    display: flex;
    gap: 0.5rem;
  }
}

.pyui_code-viewer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;


  &-questions {
    // border-left: 1px solid #ccc;
    height: 485px;
    display: flex;
    flex-direction: column;
    background: hsl(204.83deg 100% 54.51% / 11%);
    padding: 0.5rem 1rem;
    gap: 0.5rem;

    &-title {
      padding: 0.25rem;
      border-bottom: 1px solid #ccc;
    }

    &-form {
      flex-grow: 1;
      flex-basis: 100%;
      overflow: auto;
    }
  }
}

.disabled {
  // padding: 5px;
  // border: 0;
  // margin: 0px 5px;
  // font-size: 1rem;
  // background-color: transparent;
  // cursor: not-allowed;
  // border-radius: 50px;
  // color: lightgray;
  pointer-events: none;
  opacity: 0.4;
}

.completed-button {
  border: none;
  padding: 0.25rem 0.5rem;
  border-radius: 3px;
  background-color: #059862;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.4s ease-in-out;
  margin-left: 0.5rem;
}

.btn_msg_container {
  padding: 0.5rem;
  // display: flex;
  display: grid;
  grid-template-columns: 18rem 20rem;
  justify-content: space-between;
  // gap: 2.5rem;


}


.run-btn-warning {
  border: none;
  // padding-top: inherit;
  // padding-left: 4rem;
  // width: 15rem;
  border-radius: 3px;
  display: flex;
  gap: 0.25rem;
  // float: right;
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.4s ease-in-out;
  // background-color: hsl(51, 100%, 92%);
  color: var(--color-primary);
  // border-color: var(--color-primary);
}

.run-btn-warning label {
  padding: .5rem;
}

.run-test-count {
  color: var(--color-accent);
  // font-weight: 500;
}


.refbtn {
  cursor: pointer;
  border: white;
  margin-top: 5px;
  background: unset;
}

.desc-textarea {
  resize: none;
  font-size: 17px;
  text-align: justify;
  padding: 1rem;
  overflow-wrap: break-word;
  width: 100%;
  height: 100%;
}
.schedule_fromtimerpicker_selecttime {
  display: flex;
  align-items: center;
  gap: 1rem;

  .react-time-picker__wrapper {
    padding: 0.4rem;
    border-radius: 2px;
    gap: 0.25rem;

    .react-time-picker__inputGroup {
      .react-time-picker__inputGroup__amPm {
        border: 1px solid gray;
      }

      .react-time-picker__inputGroup__amPm:hover {
        outline: none;
      }
    }
  }

  .react-time-picker__clear-button {
    display: none;
  }
}
.tool-bar-wrapper {
  display: flex;
  flex-flow: wrap;
  flex-direction: column;
  grid-template-columns: auto auto auto;
  background-color: #ccc;
  /* padding: 2px; */
  /* margin-left: 0.9rem; */
  margin-top: 3rem;
  /* width: 7rem; */
  cursor: grab;
  /* gap: 0.2rem; */
  /* overflow: auto; */
  /* /* height: 5rem; */

}

.popup {
  display: none;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.declare1 {
  width: 25px;
  margin-left: 1.5px;
  position: relative;
  height: 18px;
  border: 1.4px solid black;
  /* background: #efede0; */
  /* margin-left: 2.5rem; */
  border-radius: 5px;
  /* display: flex; */
  /* justify-content: center; */
  /* padding: 0.5px; */
  margin-top: 0.3rem;
  margin-bottom: 0.7rem;
  /* align-items: center; */
  /* /* text-align: center; */
}

.declare1::before,
.declare1::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 1.4px;
  background-color: #333;
}

.declare1::before {
  left: 4px;
}

.declare1::after {
  right: 4px;
}

.react-flow__controls_icons {
  background-color: #ffffff;
  /* border: 0.8px solid black; */
  padding: 0.5px;
  /* font-size: 30px; */
  text-align: center;
  position: relative;
}


.tooltip-content {
  display: none;
  position: absolute;
  top: -120%;
  left: 100%;
  transform: translateX(10%);
  padding: 2rem 2rem 0rem 2rem;
  background-color: #fff;
  border-color: transparent rgb(255, 255, 255) transparent transparent;
  width: fit-content;
  /* padding: 2rem; */
  border-radius: 5px;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2); */
}

.react-flow__controls_icons:hover .tooltip-content {
  display: block;
}

.react-flow__controls_icons .tooltip-content::after {
  content: " ";
  position: absolute;
  top: 40%;
  right: 100%;
  /* To the left of the tooltip */
  margin-top: -5px;
  border-width: 10px;
  border-style: solid;
  /* border-left: #fff;
  border-bottom: orange;
  border-left: greenyellow;
  border-right: violet;
  border-top: pink; */
  border-left: #e02424;
  border-color: transparent rgb(255, 255, 255) transparent transparent;
}

.tooltiplabel {
  /* position: absolute; */
  /* top: 40%; */
  /* left: 35%; */
  /* transform: translate((-50%, -50%)); */
  /* display: block; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.hexagon1 {
  width: 80px;
  /* padding: 3rem; */
  /* Adjust the size as needed */
  height: 55px;
  /* The height is calculated as half the width * sqrt(3) */
  background-color: #ffffff;
  /* Adjust the background color */
  position: relative;
  border: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hexagon1:before,
.hexagon1:after {
  content: '';
  position: absolute;
  width: 0;

  border-top: 29px solid transparent;
  /* half of the width */
  border-bottom: 27.5px solid transparent;
  /* half of the width */
}

.hexagon1:before {
  left: -27.5px;
  /* half of the height */
  border-right: 27.5px solid black;
  /* border: 1px solid black; */
  /* half of the height */
}

/* zz */
.hexagon1:after {
  right: -27.5px;
  /* padding-right: 1rem; */
  /* half of the height */
  border-left: 27.5px solid black;
  /* half of the height */
}
.date-card{
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background: hsla(0, 0%, 100%, 0.99);
    transition: box-shadow 0.3s ease;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
}
// button:focus{
//     background-color: blue;
//     color: aliceblue;
// }
.selected{
    background-color: #68acd5;
    color: aliceblue;
}

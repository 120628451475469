.schedule_data_picker {
  display: flex;
  margin: 0.75rem;
  gap: 1rem;
  align-items: center;

  .react-date-picker__wrapper {
    /* Your custom styles for the input field */
    padding: 0.2rem;
    border-radius: 2px;
    gap: 0.25rem;

    .react-date-picker__clear-button {
      display: none;
    }
  }
}
.expandablerow_table{
  height: max-content;
  max-height: 30rem;
  overflow-y: auto;
}
.expandablerow {
  .expandablerow_download_table {
    display: flex;
    flex-direction: column;

    .expandablerow_download_actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 0.75rem;
      margin-block-end: 0.675rem;


      .actions {
        display: flex;
        align-items: center;
        color: white;
        gap: 0.5rem;
      }

      .expandablerow_download_title {
        font-size: 1rem;
      }
    }
  }
}
.table-header-div{
  display: flex;
    background-color: aliceblue;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
}
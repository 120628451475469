.reg-wrapper{
  width: 29rem;
  height: 40rem;
  margin: 0px auto;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, .1);
  padding: 1rem;
  overflow-y: auto;
}

.reg-form-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: relative;

  .form-field {
    display: grid;
    grid-template-columns: 1fr auto;
    grid-template-areas:
      "label label"
      "input input2"
      "msg msg";
  }

  .form-field-label {
    font-size: 0.75rem;
    grid-area: label;
  }

  .input {
    width: 100%;
  }

  .form-field-input-container {
    grid-area: input;
  }

  .form-field-input-container-2 {
    grid-area: input2;
    padding-inline-start: 0.5rem;
  }

  .form-field.radio {
    padding: 0.75rem 0;

    .form-field-label {
      display: flex;
    }

    .profile-gender-reg {
      display: inline-flex;
      align-items: center;
      gap: 0.25rem;
      margin-inline-start: 1rem;
    }

    .radiobtn {
      margin-inline-start: 0.5rem;
      cursor: pointer;
    }
  }

  .form_footer {
    margin-block-start: 0.75rem;
    border-top: 1px solid hsl(0deg 0% 80% / 39%);
    padding-top: 0.5rem;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
    align-items: center;
  }
  .error-msg {
    background: transparent;
    font-size: 0.8rem;
    grid-area: msg;
    height: 0.875rem;
    text-align: start;
  }
}

.otpButtonDiv {
  // margin-left: 1rem;
  display: inline-flex;
  gap: 1rem;
}

.otpButton {
  border: none;
  border-radius: 1px;
  background-color: var(--color-secondary);
  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  align-items: center;
  background: hsla(0, 0%, 93%, 0.49);
  border: 1px solid #eee;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 1.85rem;
  justify-content: center;
  width: 1.85rem;
  transition: all 0.4s ease-in-out;
  > svg {
    font-size: 1.75rem;
  }
}

.verified {
  border: 1.5px solid green;
  cursor: default;
  opacity: 0.7;
  border-radius: 0;
  margin: 0;
  pointer-events: none;

  &.not {
    border: 1.5px solid red;
  }

  & .disabled {
    border: 1.5px solid green;
    cursor: default;
    opacity: 0.7;
    border-radius: 0;
    margin: 0;
    pointer-events: none;
  }
}

.verified:disabled {
  pointer-events: none;
}

.registerModal {
  background-color: rgb(61, 154, 61);
}

.captcha {
  background: hsla(0, 0%, 80%, 0.24);
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
}

.captcha-input {
  // margin-left: 1.1rem;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.captcha-canvas {
  display: flex;
}

.reg-user_privacy_policy {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  &-item {
    font-size: 0.75rem;
    display: flex;
    gap: 0.25rem;
    align-items: flex-start;
  }

  .privacy_policy-input {
    margin-block-start: 0.25rem;
  }
}

// OTP POPUP
.otp-field-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 0;
}

.otp-field-wrapper:before {
  z-index: -1;
  content: "";
  position: fixed;
  background: hsl(0deg 0% 63.62% / 62%);
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}

.otp-field {
  background: #fff;
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 300px;
  max-width: 90%;
}

.otp-button {
  display: flex;
  gap: 0.5rem;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .form-field.wrapped-xs {
    grid-template-areas:
      "label label"
      "input input"
      "input2 input2"
      "msg msg";

    .form-field-input-container-2 {
      grid-area: input2;
      padding-inline-start: 0;
      margin-block-start: 0.5rem;
    }
  }
}

.pyui_login-wrapper {
  block-size: 100%;
  inline-size: 100%;
  display: flex;
  flex-direction: column;
  background-image: linear-gradient(180deg, #dfe9f3 0, #fff);
}
.pyui_login {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.pyui_login-container {
  background: rgb(255 255 255 / 88%);
  width: 25rem;
  max-width: 90%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  margin: 1rem auto;
  display: grid;
  grid-template-areas:
    "logo "
    "login"
    "powerby";
  grid-template-columns: 100%;
  grid-template-rows: auto auto auto;
  height: auto;
}

.pyui_login-panel_wrapper {
  max-width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.pyui_login-panel_logo {
  grid-area: logo;
  padding: 1.25rem 0;
  justify-self: center;
  align-self: center; // remove for height 600 at line 15

  .pyui_login-logo {
    height: 3.5rem;
    width: auto;
  }
  .tag-line {
    font-size: 1rem;
    margin-block-start: 0.5rem;
  }
}

// powered by
.pyui_powered-by {
  border-top: 1px solid #eee;
  padding-block-end: 1rem;
  text-align: center;
  grid-area: powerby;
  display: none;

  &-text {
    text-align: center;
    margin-block: 0.75rem;
    font-size: 0.75rem;
    font-style: italic;
    color: #888;
  }

  .login-dsl-logo {
    height: auto;
    width: auto;
    max-width: 5rem;
  }
}

// login form
.pyui_loginform {
  background: hsl(0deg 0% 93% / 37%);
  max-width: 100%;
  display: flex;
  align-items: center;
  grid-area: login;

  &-group {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 2rem 3rem;
    width: 100%;
  }

  &-title {
    font-size: 1.5rem;
    margin-block-end: 0.5rem;

    > span {
      font-weight: 600;
    }
  }
}

@media only screen and (max-width: 500px) {
  .pyui_login-panel_logo {
    padding: 0.75rem;
    .pyui_login-logo {
      height: 2.5rem;
    }
    .tag-line {
      font-size: 0.75rem;
      margin-block-start: 0;
      margin-inline-start: 1rem;
      display: none;
    }
  }
  .pyui_login-panel_wrapper {
    flex-direction: row;
  }
  .pyui_loginform-group {
    padding: 1.5rem 1.5rem;
  }
}
.multiSelectContainer{
  background: white;
}
.event-msg{
  display: block;
    margin: 6rem;
}
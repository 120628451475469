// bundle.scss

.bundle {
  margin: 1rem;

  .bundle-main {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;

    // background-color: #e6e6fa;
    /* Lavender background */
    padding-block-start: 20px;

    .bundle-form {
      background-color: #fffafa;
      width: 70%;
      /* Snow background */
      padding: 25px;
      border-radius: 8px;
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);

      .title {
        text-align: center;
        margin-block: 1rem;
      }

      .bundle-track-assessment {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;


        .bundle-tracks {
          display: flex;
          flex-direction: column;

          span {
            margin-bottom: 8px;
          }
        }
      }

      .bundle-markprivate {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin: 0.2rem;

        label {
          margin-bottom: 0px !important;
        }
      }

      section {
        margin-bottom: 25px;

        label {
          display: block;
          font-size: 15px;
          color: #000;
          /* Dark slate blue color */
          margin-bottom: 8px;
        }

        // .input {
        //   width: 100%;
        //   padding: 12px 18px;
        //   border: 2px solid #000;
        //   /* Dark slate blue color */
        //   border-radius: 8px;
        //   font-size: 17px;
        //   transition: border-color 0.25s ease-in-out;

        //   &:focus {
        //     border-color: var(--color-secondary-op-80);
        //     /* Slate blue color on focus */
        //     outline: none;
        //   }

        //   &::placeholder {
        //     color: #bababa;
        //     /* Light steel blue color */
        //   }
        // }
      }

      section:last-child {
        margin-bottom: 0;
      }

      footer {
        display: flex;
        justify-content: flex-end;
        margin-block-start: 0.75rem;

        .bundle-generated-text {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          color: white;
        }
      }
    }
  }
}
.graph-wrapper{
        /* width: auto; */
        background-color: #fffffffc;
        margin: 1rem;
        width: max-content;
        border-radius: 10px;
        transition: box-sh;
        transition: box-shadow 0.3s ease;
        box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}
.graph-wrapper:hover{
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);;
}

.class_count{   background: #fff;
border: 1px solid #e7e7e7;
border-radius: 100%;
flex-shrink: 0;
font-size: .75rem;
margin-inline-start: auto;
padding: .25rem;}
.elemts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.oval {
  display: flex;
  position: relative;
  height: 50px;
  width: 100px;
  background-color: #fbd086;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: black;
}

.ovalEnd {
  display: flex;
  height: 50px;
  width: 100px;
  /* background-color: #2d8d95; */
  background-color: #fbd086;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  color: black;
}

.rectangle {
  display: flex;
  height: 50px;
  width: 100px;
  background-color: #fbd086;
  justify-content: center;
  align-items: center;
  color: black
}


.inputrectangle {
  /* min-width: 130px; */
  /* min-height: 70px; */
  width: 100px;
  height: 50px;
  transform: skew(-20deg);
  background: #fbd086;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 25px solid #555;
}

.parallelogram {
  width: 100px;
  height: 50px;
  transform: skew(-20deg);
  background: #fbd086;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;

}

.square {
  min-height: 50px;
  min-width: 50px;
  background-color: #555;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .diamond{
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-bottom-color: red;
    position: relative;
    top: -50px;
}
.diamond:after {
    content: '';
    position: absolute;
    left: -50px;
    top: 50px;
    width: 0;
    height: 0;
    border: 50px solid transparent;
    border-top-color: red;
  } */
.vl {
  border-left: 6px solid #555;
  height: 100px;

}

.declare {
  width: 100px;
  height: 50px;
  position: relative;
  border: 1px solid #ccc;
  background: #fbd086;

  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.declare::before,
.declare::after {
  content: '';
  position: absolute;
  height: 100%;
  width: 2px;
  background-color: #333;
}

.declare::before {
  left: 1rem;
}

.declare::after {
  right: 1rem;
}

.hexagon {
  width: 100px;
  /* Adjust the size as needed */
  height: 50px;
  /* The height is calculated as half the width * sqrt(3) */
  /* background-color: #3498db; */
  background-color: #fbd086;
  /* Adjust the background color */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hexagon:before,
.hexagon:after {
  content: '';
  position: absolute;
  width: 0;
  /* border-top: 29px solid transparent; */
  border-top: 23.8px solid transparent;
  /* half of the width */
  border-bottom: 23px solid transparent;
  /* half of the width */
}

.hexagon:before {
  left: -26px;
  /* half of the height */
  border-right: 27px solid #fbd086;
  /* half of the height */
}

.hexagon:after {
  right: -26px;
  /* half of the height */
  border-left: 27px solid #fbd086;
  /* half of the height */
}
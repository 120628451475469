.pyui_main-appbar {
  display: flex;
  min-height: 64px;
  height: 3.5rem;
  background: var(--color-bg-light);
  color: var(--color-text-default);
  box-shadow: 0 0.5rem 0.5rem -0.5rem rgb(0 0 0 / 20%);
  //0 1px 4px rgb(0 0 0 / 22%); // 0 0.5rem 0.75rem rgb(0 0 0 / 15%);
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 10;
  // overflow: hidden;

  &>.pyui_logo {
    // TODO revisit again
    // height: 2rem;
    // width: auto;

    height: auto;
    width: 8rem;
    flex-shrink: 0;
    overflow: hidden;
    // margin-block-start: 0.5rem;
  }

  &>.pyui_title {

    font-size: 1.5rem;
    flex-grow: 1;
    font-weight: 500;
    text-align: start;
    border-inline-start: 2px solid #ccc;
    padding-inline-start: 0.5rem;
    margin: 0;
  }

  &>.pyui_navlinks {
    display: flex;
    align-items: center;
    gap: 0.75rem;
  }

  .pyui_avatar {
    background: #eee;
    // z-index: 3;
    position: relative;
    // color: var(--color-text-default);
    color: #4199ce;
    border: 0;
    border-radius: 100%;
    height: 2rem;
    width: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    // box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 10%);
  }

  .pyui_trophy {
    // background: #eee;
    // z-index: 3;
    position: relative;
    // color: var(--color-text-default);
    // color: #4199ce;
    border: 0;
    border-radius: 100%;
    height: 2rem;
    width: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    // cursor: pointer;

    // box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 10%);
    &-image {
      width: 50px;
      height: 50px;
    }
  }
}


.pyui_dropdown-profile {
  position: absolute;
  z-index: 3;
  top: 2.25rem;
  right: 0rem;
  background-color: #fff;
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 10%);
  transition: all 0.3s ease-in;
  border: 1px solid hsl(0deg 0% 52.94% / 21.96%);
  padding: 0.5rem;
  border-radius: 0.5rem;
  // padding: 0.25rem;
}

.pyui_dropdown-profile .pyui_profile {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  // display: block;
  white-space: nowrap;
  padding: 0.35rem 0.75rem;
  font-size: 0.875rem;
  border-bottom: 1px solid #eee;
  font-weight: 400;
  color: var(--color-text-default);

  .title_icon {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}

.pyui_dropdown-profile .pyui_profile:hover {
  background-color: rgb(228 243 255);
}

.pyui_dropdown-profile ul>li:last-child .pyui_profile {
  border-bottom: 0;
}
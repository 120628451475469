.success-message {
  display: flex;
  flex-direction: column;
  align-items: center;

  .form-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border: 1px solid green;
    border-radius: 50%;
  }

  .message1 {
    font-size: 1rem;
    text-align: center;
    background-color: white;
    padding: 1em;
    border-radius: 0.5em;
    // box-shadow: 0 0.5em 1em rgba(0, 0, 0, 0.3);
  }
}

.formui {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 25rem;

  .formui-data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.75rem;

    label {
      width: 6rem;
    }

    .finput {
      flex: 1;
      /* Fill the available space */
    }
  }

  .formui-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
  }
}
.test_template {
  // margin: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;

  .test_template_content {
    padding: 1rem;
    flex-grow: 1;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
  .template_details_actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    .archive_buttons {
      display: inline-flex;
      align-items: center;
      overflow: hidden;
      border: 1px solid #ccc;
      border-radius: 0.25rem;

      button {
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0.5rem 0.7rem;
      }

      .left-half,
      .right-half {
        background-color: #fff;
        border-color: var(--color-secondary);
        color: var(--color-secondary);

        &:hover {
          background-color: rgb(255 255 255 / 48%);
          color: #666;
          transition: all 0.2s ease-in-out;
        }

        &:disabled {
          pointer-events: none;
          // opacity: 0.4;

          color: #ccc;
          background: #f2f2f2;
        }

        > svg {
          fill: currentColor;
        }
      }
    }
  }

  .test_template_actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }
}

/* Popup container - can be anything you want */
.popup-msg {
    position: relative;
    top: 3px;
    display: inline-flex;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    padding: 0;
    background: none;
    border: none;
    align-items: center;
}

/* The actual popup-msg */
.popup-msg .popuptext {
  visibility: hidden;
  width: 26rem;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -13rem;
}

/* Popup arrow */
.popup-msg .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Toggle this class - hide and show the popup-msg */
.popup-msg .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
  padding: .5rem;
}

/* Add animation (fade in the popup-msg) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;} 
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}

// @use "../../assets/styles/_Scss/placeholder";

// This is main outer common structure
.app-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 100%;
  background: linear-gradient(180deg, #dfe9f3 0, #fff);

  .app-content {
    // block-size: 100%;
    inline-size: 100%;
    flex-grow: 1;
    overflow: auto;
    // background-image: linear-gradient(to bottom, #dfe9f3 0%, white 100%);
    padding-block-start: 4rem;

    > div {
      height: 100%;
      overflow: auto;
    }
  }

  .app-footer {
    flex-shrink: 0;
  }
}

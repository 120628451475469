.display_data_test {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .display_data_title_table {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    gap: 1rem;

    .display_data_title_rcount {
      display: flex;
      align-items: center;
      gap: 2rem;

      .display_data_rows_count {
        font-weight: 500;
      }
    }

    .display_data_icon {
      display: flex;
      align-items: center;
      justify-self: flex-end;
      // margin: 0rem 2.85rem;
      transition: all 0.4s ease-in-out;

      // button {
      //   background-color: transparent;
      //   border: none;
      // }

      .archive_button {
        display: inline-flex;
        align-items: center;
        overflow: hidden;
        border: 1px solid #ccc;
        border-radius: 0.25rem;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          outline: none;
          cursor: pointer;
          padding: 0.5rem 0.7rem;
        }

        .left-half,
        .right-half {
          background-color: #fff;
          border-color: var(--color-secondary);
          color: var(--color-secondary);

          &:hover {
            background-color: rgb(255 255 255 / 48%);
            color: #666;
            transition: all 0.2s ease-in-out;
          }

          &:disabled {
            pointer-events: none;
            // opacity: 0.4;

            color: #ccc;
            background: #f2f2f2;
          }

          > svg {
            fill: currentColor;
          }
        }
      }

      .display_data_icon_button {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 2.5rem;
        height: 2.5rem;
        // background-color: var(--color-primary);
        // border-color: var(--color-primary);
        border: none;
        padding: 0;
        cursor: pointer;

        &:hover {
          background-color: #ddd;
        }
      }
    }
  }

  .display_data {
    overflow-y: scroll;
    max-height: 27rem;

    .input-search {
      display: grid;
      grid-template-columns: 1fr 1rem;
      align-content: center;
    }

    tbody {
      tr {
        .completed {
          color: #fff;
          background-color: var(--toastify-color-success);
        }

        .expired {
          color: #fff;
          background-color: var(--toastify-color-dark);
        }

        .canceled {
          color: #fff;
          background-color: var(--toastify-color-error);
        }
      }
    }

    .sticky-header {
      position: sticky;
      top: 0;
      z-index: 1;
    }

    .search-input {
      border: 1px solid var(--color-input-br);
      padding: 0.5rem;
      border-radius: 3px;
      font-size: 0.875rem;
      color: var(--color-text-default);
      background: #fff;
      transition: all 0.2s ease-in;
      width: 7rem;

      &:hover {
        border: 1px solid var(--color-input-br-hover);
      }

      &:focus {
        outline: 1px solid var(--color-input-focus);
        border-color: var(--color-input-focus);
        transition: all 0.2s ease-in;
      }

      &:disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.4;
      }
    }

    .display_data_actions {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      gap: 0.25rem;

      .data_actions {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    .display_data_actions.not_visible {
      display: flex;
      align-items: center;
    }
  }
}
 
.spinner {
  width: 20px;
  height: 19.5px;
  border-radius: 100%;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #98bdf5;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.options-div {
  position: absolute;
  z-index: 5;
  padding: 1.5rem 1.5rem 1.5rem 1rem;
  width: 5rem;
  border-radius: 10px;
  background-color: white;
  box-shadow: 10px 10px 10px 10px rgba(0, 0, 0, 0.2);
}

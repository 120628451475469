.custom-drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  /* Adjust the width as needed */
  height: 85vh;
  /* Cover the entire height of the viewport */
  background-color: white;
  /* Set the background color */
  z-index: 10;
  /* Set a high z-index to ensure it appears above other content */
  overflow-y: hidden;
  /* Enable vertical scrolling if content exceeds viewport height */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 0 20px rgba(0, 0, 0, 0.1);
  /* Add shadow for visual depth */
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  // gap: 1rem;
}

.closeButton {
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: -1rem;
  right: -1rem;
}

/* PaginationEditor.scss */

.pagination-editor {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.2rem;

  .pagination-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    // border: none;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 2px solid rgba($color: gray, $alpha: 1);
    cursor: pointer;
    font-size: 0.8rem;

    &:hover,
    &:focus {
      outline: none; // Remove default outline
      background-color: rgba($color: gray, $alpha: 0.1); // Subtle hover effect
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5; // Dim disabled buttons
    }

    &.active {
      background-color: rgba($color: #34B433, $alpha: 1); // Highlight active 
      font-weight: bold; // Make active page number stand out
      border: 2px solid #1A4D1B;
      color: #fff;
    }
  }

  .pagination-button-pre_next {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    background-color: rgba($color: #000, $alpha: 0.1);
    border: none;
    padding: 0.3rem 0.5rem;
    cursor: pointer;
    font-size: 0.8rem;

    &:hover,
    &:focus {
      outline: none;
      background-color: rgba($color: #000, $alpha: 0.2);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5; // Dim disabled buttons
    }

    &.attempted {
      border-color: green;
      /* Color for attempted exercises */
    }

    &.not-attempted {
      border-color: gray;
      /* Color for not attempted exercises */
    }
  }

  span {
    // Ellipsis for omitted page numbers
    padding: 0.5rem 1rem;
    font-weight: bold;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 1rem 0; // Add some margin for data list

    li {
      padding: 0.5rem 1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      &:last-child {
        // Remove border from last item
        border-bottom: none;
      }
    }
  }
}
@media only screen and (max-width: 600px) {
  .infobar-actions {
    grid-template-columns: 1fr auto;
  }
  .pagination_infobar{
    display: none
  }
}
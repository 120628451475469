.dropdown-wrapper{
    display: flex;
    align-items: baseline;
    gap: .5rem;
}
.option-outline{
    outline: 2px solid var(--color-input-focus);
    border-color: var(--color-input-focus);
    transition: all 0.2s ease-in;
    border-radius: 10px;
}
.option-wrapper{
    height: 10rem;
    overflow: auto;
    border-radius: 5px;
    border: 1px solid #9d949499;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.5);
}
.expanded::after {
    top: 14px;
    transform: rotate(-135deg);
    transition: transform 0.3s ease-in;
  }
  .pyui_session {
    width: 15rem;
    min-width: 10rem;
    max-width: 100%;
    position: relative;
    user-select: none;
  
    &-inner {
      display: flex;
      align-items: center;
      border-radius: 5px;
      justify-content: space-between;
      cursor: pointer;
      font-size: 0.875rem;
      background-color: white;
      padding: 0.9rem 1rem; //0.5rem 1rem
      box-shadow: rgba(149, 157, 165, 0.4) 0px 0px 20px;
  
      &::after {
        display: block;
        content: "";
        width: 0.5rem;
        height: 0.5rem;
        border-right: 2px solid var(--color-text-default);
        border-bottom: 2px solid var(--color-text-default);
        transform: rotate(45deg);
        position: absolute;
        right: 15px;
        top: 20px;
        transition: all 0.3s;
      }
  
      &.expanded::after {
        top: 20px;
        transform: rotate(-135deg);
        transition: transform 0.3s ease-in;
      }
    }
  
    &-list {
      position: absolute;
      top: 105%;
      width: 15rem;
      background-color: white;
      transition: all 0.3s ease-in;
      z-index: 3;
  
      &-item {
        font-size: 0.875rem;
        cursor: pointer;
        color: var(--color-text-default);
        padding: 0.5rem 1rem;
        border-bottom: 1px solid #eee;
        transition: all 0.1s ease-in;
  
        &-date {
          font-size: 0.875rem;
          cursor: pointer;
          color: var(--color-text-default);
          padding: 0.5rem 1rem;
  
          // margin-top: 0.525rem;
          // border-bottom: 1px solid #eee;
          // transition: all 0.1s ease-in;
          // & .date-picker-title {
          //   margin-bottom: 0.525rem;
          // }
  
          &::after {
            display: block;
            content: "";
            width: 0.5rem;
            height: 0.5rem;
            border-right: 2px solid var(--color-text-default);
            border-bottom: 2px solid var(--color-text-default);
            transform: rotate(45deg);
            position: absolute;
            right: 15px;
            top: 12.5rem;
            transition: all 0.3s;
          }
  
          &.expand::after {
            top: 12.85rem;
            transform: rotate(-135deg);
            transition: transform 0.3s ease-in;
          }
        }
      }
  
      &-item:not(.disabled):hover {
        background-color: rgba(156, 39, 176, 0.2);
      }
  
  
    }
  
    & .disabled {
      cursor: default;
      opacity: 0.7;
      border-radius: 0;
      margin: 0;
    }
  }
@use "../../assets/styles/_Scss/placeholder";

.tabs-container {
  @extend %tabContainer;
}

.tabs {
  @extend %tabs;

  > li {
    flex-grow: 1;
    @extend %tabItemAlternate; //%tabItem;
  }

  > li.active {
    @extend %tabItemAlternateSelected; //%tabItemSelected;
  }
}
// .tabs-container {
//   display: flex;
//   flex-direction: column;
//   margin: 1rem;
//   /* Adjust as needed */
// }

// .tabs {
//   display: flex;
//   list-style: none;
//   padding: 0;
//   margin: 0;
//   border-radius: 5px;
//   font-size: 16px;
//   color: #fff;
//   /* Adjust as needed */
//   // background-color: #f2f2f2;
// }

// .tabs li {

//   border-radius: 2.1rem;

//   padding: 10px 15px;
//   /* Adjust as needed */
//   text-align: center;
//   cursor: pointer;
//   transition: background-color 0.2s ease-in-out;

//   &.small {
//     padding: 0.25rem 0.75rem;
//   }
// }

// .tabs li.active {
//   background-color: #fff;
//   color: #fff;
//   background-color: rgb(59, 50, 179);
// }

// .tabs li:not(.active) {
//   color: #777;
// }

// .tabs li:hover {
//   background-color: #eee;
// }

// /* Optional customizations */

// // .tabs {
// //   border-bottom: 1px solid #ddd;

// // }

// .tabs li {
//   margin-right: 10px;
//   /* Add optional spacing between tabs */
// }

// .tabs li.active {
//   font-weight: 600;
//   background-color: rgb(59, 50, 179);
//   /* Add optional font weight change for active tab */
// }

// .tabs li {
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
//   /* Add optional shadows */
// }

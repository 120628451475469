.modal_C11w1 {
    position: absolute;
    z-index: 3;

    background-color: #fff;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in;
    border: 1px solid hsla(0deg, 0%, 52.94%, 0.2196);
    padding: 0.5rem;
    border-radius: 0.5rem
}

.header_CS22 {
    align-items: center;
    justify-content: center;
    display: flex;
    font-size: 20px;
    font-weight: 550;
}

.assessmentcards_main {
    margin: 1.5rem 0rem;

    .assessmentcards_main_grid {
        display: grid;
        gap: 1.5rem;
        grid-template-columns: repeat(auto-fit, minmax(20rem, 24rem));
        grid-auto-rows: 150px;
        // grid-template-columns: repeat(3, minmax(0, 1fr));
        justify-content: center;

        .testlibrarycard {
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            transition: box-shadow 0.3s ease;
            box-shadow: 0 0 0 rgba(0, 0, 0, 0);
            user-select: none;

            .testlibrarycard_header {
                color: #000000;
            }

            .testlibrarycard_time_ques {
                display: flex;
                flex-wrap: wrap;
                align-items: flex-end;
                gap: 1rem;
                color: #bbb;
            }

            .testlibrarycard_footer_buttons {
                display: flex;
                align-items: center;
                gap: 0.75rem;
                cursor: pointer;
                // max-width: max-content;
                justify-content: right;
            }
        }

        .beginner {
            border-color: #6eff7a;
            /* Light green for Easy */
            color: #000000;
            /* Black text for contrast */
        }

        .intermediate {
            border-color: #ffdd57;
            /* Yellow for Medium */
            color: #000000;
            /* Black text for contrast */
        }

        .scholar {
            border-color: #ff6464;
            /* Red for Hard */
            color: #ffffff;
            /* White text for contrast */
        }

        .testlibrarycard:hover {
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        }
    }
}

.label_no {
    margin-top: 5rem;
    left: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    user-select: none;
}
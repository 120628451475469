.sqltable-main{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;

    .sqltable-dropdown{
        display: flex;
        align-items: center;
        gap: 0.25rem;
    }
}
.sql-playground-title{
    display: flex;
    font-size: 16px;
    font-family: auto;
    background-color: white;
    padding: 1rem;
}

.sql-run-test-count{
    display: inline-block;
    float: inline-end;
    // margin:0.2rem 1.5rem 0  0;
    color: var(--color-accent);

}

.tables-icon-div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .25rem .5rem;
}
.pyui_category {
    width: 20rem;
    min-width: 10rem;
    max-width: 100%;
    position: relative;
    user-select: none;
  
    &-inner {
      display: flex;
      align-items: center;
      border-radius: 5px;
      justify-content: space-between;
      cursor: pointer;
      font-size: 0.875rem;
      background-color: white;
      padding: 0.5rem 1rem;
      box-shadow: rgba(149, 157, 165, 0.4) 0px 0px 20px;
  
      &::after {
        display: block;
        content: "";
        width: 0.5rem;
        height: 0.5rem;
        border-right: 2px solid var(--color-text-default);
        border-bottom: 2px solid var(--color-text-default);
        transform: rotate(45deg);
        position: absolute;
        right: 15px;
        top: 11px;
        transition: all 0.3s;
      }
  
      &.expanded::after {
        top: 14px;
        transform: rotate(-135deg);
        transition: transform 0.3s ease-in;
      }
    }
  
  
  
    &-list {
      position: absolute;
      top: 105%;
      width: 20rem;
      background-color: white;
      transition: all 0.3s ease-in;
      z-index: 20;
  
      &-item {
        font-size: 0.875rem;
        cursor: pointer;
        color: var(--color-text-default);
        padding: 0.5rem 1rem;
        border-bottom: 1px solid #eee;
        transition: all 0.1s ease-in;
  
        &.selected {
          // background-color: rgba(109, 107, 107, 0.1);
          font-weight: bold;
        }
  
        &-date {
          font-size: 0.875rem;
          cursor: pointer;
          color: var(--color-text-default);
          padding: 0.5rem 1rem;
  
          &::after {
            display: block;
            content: "";
            width: 0.5rem;
            height: 0.5rem;
            border-right: 2px solid var(--color-text-default);
            border-bottom: 2px solid var(--color-text-default);
            transform: rotate(45deg);
            position: absolute;
            right: 15px;
            top: 12.5rem;
            transition: all 0.3s;
          }
  
          &.expand::after {
            top: 12.85rem;
            transform: rotate(-135deg);
            transition: transform 0.3s ease-in;
          }
  
        }
      }
  
      &-item:not(.disabled):hover {
        background-color: rgb(228, 243, 255);
      }
    }
  
    & .disabled {
      cursor: default;
      opacity: 0.7;
      border-radius: 0;
      margin: 0;
    }
  }
  .icon-button2 {
    align-items: center;
    color: var(--color-linkbutton-fg);
    cursor: pointer;
    display: flex;
    font-size: 0.875rem;
    font-weight: 400;
    justify-content: center;
    background: rgb(238, 238, 238);
    border-width: 1px;
    border-style: solid;
    border-color: rgb(204, 204, 204);
    border-image: initial;
    border-radius: 5px;
    margin: 0px;
    padding: 0.5rem;
    text-decoration: underline;
    transition: 0.4s ease-in-out;

  
    > svg {
      height: 1.25rem;
      width: 1.25rem;
      color: currentColor;
    }
  
    &.cancle {
      color: var(--toastify-color-error);
    }
    &.start {
      color: var(--color-icon-primary);
    }
  
    &:hover {
      color: var(--color-linkbutton-fg-hover);
      border-color: var(--color-linkbutton-fg-hover);
      transition: all 0.2s ease-in-out;
    }
  
    &.small {
      font-size: 0.75rem;
      
  
      > svg {
        height: 1rem;
        width: 1rem;
        color: currentColor;
      }
    }
  
    &.disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  
    //secondary button // blue
    &.secondary {
      background-color: var(--color-secondary);
      border-color: var(--color-secondary);
      color: white;
  
      &:hover {
        background-color: var(--color-secondary-op-80);
        //  transition: all 0.2s ease-in-out;
      }
    }
  }
.pravi_dashboard_main {
  display: flex;
  flex-direction: column;
  margin: 2.5rem;
  border-radius: 10px;
  // overflow: hidden;
  gap: 2rem;

  .dashboard_title {
    margin: 3rem 0rem;
    text-align: center;
    font-size: 1.5rem;
    color: #bbb;
  }
}
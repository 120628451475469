.MuiDialogContent-root {
    border-bottom: 1px solid #eee;
    padding: 1.5rem 2rem !important;
    min-width: 35rem;
}



.registration-link {
    margin-top: 10px;
    display: inline-block;
}
.action-btn{
    display: flex;
    gap: .5rem;
}
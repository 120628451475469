.test_greeting {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 0.75rem 0rem;
  justify-content: center;
  align-items: center;

  .test_greeting_msg {
    font-size: 1.25rem;
  }

  .test_greeting_buttons {
    display: flex;
    justify-content: right;

    .btn_greet {
      display: flex;
      align-items: flex-end;
      gap: 0.25rem;
    }
  }
}

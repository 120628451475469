.center {
  /* margin: auto; */
  width: 70%;
  margin-left: 17%;
  border: 3px solid rgb(251 251 251);
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  height: 70%;
  padding: 10px;
  margin-top: 10%;
  border-radius: 10px;
  padding: 2%;
  gap: 4rem;
  background-color: rgb(251 251 251);
}

.evalip {
  padding: 10px;
  border-radius: 5px;
}

.evalution {
  text-align: center;
  padding: 4rem;
}

.buttons {
  margin-left: 30%;
  color: black;
}

.classlevel {
  position: relative;
  height: 70px;
  left: 60%;
  margin-bottom: -10%;
  color: red;
}

.left .minlevel {
  float: left;
  width: 35%;
  /* background-color: aqua; */
}

.maxlevel {
  margin-left: 60px;
  float: left;
  width: 55%;
  /* background-color: orange; */
}

.maxlevel1 {
  margin-left: 60px;
  float: left;
  width: 40%;
  /* background-color: red; */
}

.minlevel1 {
  float: left;
  width: 35%;
  /* background-color: aqua; */
}

.minlevel {
  width: max-content;
  display: inherit;
}

.category {
  height: 2%;
  width: 100%;
  display: inline-flex;
  /* background-color: yellow; */
}

.customtext {
  padding: 9px;
  border-radius: 5px;
}

.rows1 {
  padding-top: 1rem;
  margin-top: 1.5rem;
}

.rows2 {
  /* margin-top: 2rem; */
}

.rows3 {
  /* padding-top: 1rem; */
  margin-top: 1.5rem;
}

.ipread {
  height: 36px;
  position: relative;
  align-self: center;
  display: inline-flex;
  top: 10px;
  width: 200px;
}

/* button:hover {
  background-color: yellow;
} */

/* select
{
    width:  40%;
    height: 25px;
    padding: 18px !important;
    border-radius: 5px;
} */

.assement-wrapper {
  display: flex;
  padding: 2rem 1rem 1rem 1rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  flex-direction: column;
}
.page_header {
  padding-bottom: 1rem;

  .title {
    font-size: 1.5rem;
    font-weight: 400;
    color: var(--color-heading-text-secondary);
    border-bottom: 1px solid #ccc;
    padding-bottom: 0.25rem;
  }
}

.flex-wrapper1 {
  display: flex;
  flex-grow: 1;
  gap: 4rem;
}

.assement_track {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-top: 0.4rem;
}

.asseement-row1 {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.searchWrapper {
  /* min-width: 50%; */
}

.seperate_clas {
  width: 50%;
}

.assement-header {
  /* margin-top: 0.3rem; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 6px 6px 0 0;
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
  /* height: 2rem; */
  &-inner {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    font-weight: 400;
  }
}

.assement-main {
  // margin: 0.5rem 0.3rem;
  display: flex;
  flex-direction: column;
  background: #fff;
  // margin-block-end: 1rem;
  padding: 1rem;
  overflow: auto;
  gap: 0.5rem;
}

/* .hr-line{
  margin-top: 0.4rem;
} */

.table-box-summary {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  /* background-color: #fff; */
  /* padding: 0rem 0.5rem; */
  /* // width: 100%; */
}

.inputbox {
  /* width:90%; */
  text-align: center;
}

.assement_fbuttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
}

.loader-class {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fileInputField {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
}

.fileInputField input#file-input {
  display: none;
  height: 0rem;
  width: 0.1rem;
  padding: 6px;
}

.fileInputField .downloadfile {
  background-color: #3399ff;
  padding: 6px;
}

.fileInputField input#file-input + .input-file-label {
  background-color: var(--color-secondary);
  padding: 0.25rem 0.5rem;
  color: #fff;
  border-radius: 5px;
}

.fileInputField .no-file {
  color: #aaa;
}

.MuiDialogTitle-root {
  background: var(--color-secondary);
  color: #fff !important;
}

.MuiDialogContent-root {
  border-bottom: 1px solid #eee;
  padding: 1.5rem 2rem !important;
}

.schedule_main {
  margin-top: 2rem;
  padding: 1rem;
  width: 100%;

  .schedule_main_header {
    margin-top: 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-bottom: 1px solid #eee; */
    border-radius: 6px 6px 0 0;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
    font-size: 1.094rem;
    height: 2rem;
  }

  .schedule_main_footer {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}

.pyui_section_content {
  display: flex;
  flex-direction: column;
  background: #fff;
  margin-block-end: 1rem;
  padding: 1rem;
}

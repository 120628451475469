

.graph-div{
    position: absolute;
    width: 50%;
    height: 80%;
    top: 1rem;
    justify-self: center;
    /* display: flex; */
    /* left: 50%; */
    /* justify-content: center; */
    background-color: aliceblue;
    align-items: center;
    /* align-self: center; */
    /* justify-content: center; */

}
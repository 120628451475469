.schedule_selectuserids {
  gap: 1rem;
  display: flex;
  flex-direction: column;
  /* margin-top: 1rem; */
  background: #fff;
  padding: 1rem;

  .schedule_selectuserids_input_samplefile {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .schedule_selectuserids_input {
      position: relative;
      display: flex;
      align-items: center;
      gap: 1rem;

      .selectuserids_input_filename {
        font-size: 0.85rem;

        input[type="file"] {
          position: absolute;
          opacity: 0;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          cursor: pointer;
        }
      }

      .selectuserids_label {
        display: inline-block;
        padding: 0.4rem 0.93rem;
        background-color: #3498db;
        color: #fff;
        border-radius: 5px;
        cursor: pointer;
      }
    }

    .selectuserids_samplefile {
      display: flex;
      align-items: center;
      gap: 0.25rem;
      // justify-self: flex-end;
    }
  }
}

.action-btn-div {
  display: flex;
  gap: 0.5rem;
}
.title-header-container {
  position: relative;
}

.title-header {
  position: absoulte;
  bottom: 0rem;
  z-index: 10;
}

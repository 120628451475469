.modal-backlayer {
  position: fixed;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  left: 0;
  top: 0;
  z-index: 1;
  transition: all 0.4s ease-in;
}
.container {
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: white;
  width: 70%;
  min-width: 14rem;
  min-height: 10rem;
  height: auto;
  max-height: 80vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1rem;
  box-shadow: 5px 5px 10px 10px rgb(0 0 0 / 8%);
  z-index: 10;
  border-top: 4px solid var(--toastify-color-warning);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  > h3 {
    font-size: 1.45rem;
    color: var(--toastify-color-warning);
    border-bottom: 1px solid #ccc;
    font-weight: 400;
    padding-block-end: 0.25rem;
  }
}
.confirmation-text {
  display: flex;
  color: black;
  // margin: 0.5rem 0 2rem;
  padding: 0 1rem;
  text-align: left;
  line-height: 2rem;
  font-size: 1.1rem;
  justify-content: center;
  
}
.dialog-title{
  display: flex;
  // background-color: #f2f2f2;
  border-bottom: 1px solid #ccc;
    padding: .5rem;
    justify-content: space-between;
    // border-radius: 10px;
}
.confirmation-title{
  display: flex;

  gap: .5rem;
  > h3 {
    font-size: 1.45rem;
    color: var(--toastify-color-warning);
    font-weight: 400;
    padding-block-end: 0.25rem;
  }
}


.confirmation-text {
  display: flex;
  margin: 0.5rem 0 2rem;
  text-align: center;
  font-size: 0.875rem;
  padding: 0.5rem;
}

.button-container {
  display: flex;
  margin-top: auto;
  align-self: flex-end;
  gap: 1rem;
  justify-content: space-between;
}

.App {
  text-align: center;
  padding: 20px;
  position: relative;
}

.columns {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.question {
  margin: 20px 0;
}

.result {
  margin-top: 20px;
  font-size: 1.2em;
  font-weight: bold;
}

.column {
  margin: 0 60px;
}

.draggable-item {
  padding: 10px;
  margin: 10px 0;
  background-color: #4CAF50;
  color: white;
  cursor: pointer;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #4CAF50;
  cursor: pointer;
  margin: auto;
  margin-right: 10px;
  display: inline-block;
}

.svg-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.button_intern {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.result {
  margin-top: 20px;
  font-size: 18px;
}

.img_button {
  max-width: 100%;
  height: auto;
}



.git-quiz-1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  margin: 0 5rem;
}

.git-quiz-1 h1 {
  margin: 0;
  font-size: 1.5rem;
  color: #333;
}

.git-quiz-1 label {
  font-weight: bold;
  color: #555;
}

.git-quiz-1 form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.Quiz-question {
  background-color: #fff;
  padding: 1rem;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.Quiz-question label {
  display: block;
  margin-bottom: 0.5rem;
}

.Quiz-form {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 1rem;
  padding-right: 1rem;
}

.form-quiz-label {
  display: flex;
  gap: 0.5rem;
  /* align-items: flex-start; */
}

.form-group-quiz {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  padding: 0 0.25rem;
  margin-bottom: 1rem;
}
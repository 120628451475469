.schedule_markingscheme {
  display: flex;
  margin: 0.75rem;
  gap: 1rem;
  align-items: center;

  select {
    border: 1px solid gray;
    outline: none;
    padding: 0.75rem;
  }
}
.dashboard_cards_main {
  .dashboard_cards_main_grid {
    display: grid;
    gap: 1.5rem;
    // grid-template-columns: repeat(auto-fit, minmax(20rem, 24rem));
    grid-template-columns: repeat(4, minmax(0, 1fr));
    justify-content: center;

    .dashboard_card {
      display: flex;
      flex-direction: column;
      text-align: center;
      border: 1px solid #ccc;
      border-radius: 5px;
      padding: 1rem 1rem;
      gap: 0.25rem;
      background: #fff;
      transition: all 0.4s ease-in;

      .dashboard_card_title {
        font-size: 1rem;
        // margin-bottom: 1.75rem;
      }

      .dashboard_card_icon {
        color: hsl(0deg 0% 20% / 50%);
        transition: all 0.4s ease-in;
        > svg {
          height: 3rem;
          width: 3rem;
        }
      }

      .dashboard_card_button {
        display: flex;
        margin-top: 0.75rem;

        .button {
          width: 100%;
        }
      }

      &:hover {
        border-color: var(--color-black);
        // background-color: hsl(46.42deg 100% 59.47% / 12%);

        .dashboard_card_icon {
          color: var(--color-primary);
        }
      }
    }
  }
}

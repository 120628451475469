.pyui-evalpanel {
  &-container {
    flex-grow: 1;
    flex-basis: 100%;
    background: rgb(149 157 165 / 42%);
    position: absolute;
    inset-inline: 0;
    inset-block: 0;
    z-index: 4;
  }

  &-content {
    position: absolute;
    transform: translate(-50%, -50%);
    inset-inline-start: 50%;
    inset-block-start: 50%;
    background: #fff;
    padding: 2.5rem 3rem;
    z-index: 1;
    width: auto;
    min-width: 400px;
    max-width: 90%;
    border-radius: 5px;
    border-top: 5px solid #7b1fa2;
    box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
    font-size: 0.875rem;
  }

  &-content.grid {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5rem;
  }

  &-no-content {
    position: absolute;
    transform: translate(-50%, -50%);
    inset-inline-start: 50%;
    inset-block-start: 50%;
    // background: #fff;
    // padding: 2.5rem 3rem;
    z-index: 1;
    width: auto;
    min-width: 400px;
    max-width: 100%;
    border-radius: 5px;
    // border-top: 5px solid #7b1fa2;
    box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
    font-size: 0.875rem;
  }

  &-action {
    grid-column: 1 / -1;
    margin-block-start: 0.5rem;

    .primary-button {
      width: 100%;
      margin-inline-start: 0;
      padding: 0.5rem;
    }
  }
}

.pyui-display {
  font-weight: 600;
}

// Greetings page

.eval-result {
  display: flex;
  gap: 0.5rem;

  &-content {
    flex-grow: 1;
    padding: 1rem;
    font-size: 2rem;
    text-align: center;
  }

  &-title {
    display: block;
    font-size: 0.875rem;
  }

  &-outof {
    font-size: 1rem;
  }

  &-actions {
    margin-block-start: 1rem;
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;

    .completed-button,
    .primary-button {
      padding: 0.5rem 0.75rem;
    }
  }

  .success {
    color: hsl(155deg 100% 25%);
  }

  .information {
    color: hsl(198deg 73% 46%);
  }
}

.pyui-evalpanel-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  column-gap: 0.5rem;
  margin-bottom: 0.7rem;
}
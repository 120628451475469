.pyui_main-content-section{
  padding: 0.5rem 1rem;
  display: flex;
  flex-direction: column;

 
}

.Profile_Lable_section{
  margin: 1.5rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .settings_title{
    display: flex;
    align-items: center;
    gap: 0.35rem;
  }

  .settings_actions{
    display: flex;
    align-items: center;
    gap: 0.35rem;
  }

  h3{
    text-align: center;
    font-size: 20px;
    font-weight: 500;
  }
}
.pyui_journeytabs-container {
  display: flex;
  gap: 1.5rem;
  background: rgb(255 255 255 / 42%);
  border: 1px solid rgb(229 229 229 / 57%);
  padding: 1rem;

}

.pyui_journeytabs-status-tabs {
  max-width: 16rem;
  width: 30%;
  // padding-block-start: 1rem;
  border-right: 1px solid #ccc;
  position: relative;

  &-jtlist {
    display: flex;
    flex-direction: column;
    position: sticky;
    // top: 10%;
    height: 12%;
    // bottom: 50%;

    &>li {
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      padding: 0.5rem 1rem;
      cursor: default;
      border-inline-end: 3px solid transparent;
    }

    &>li.active {
      background: var(--color-bg-light);
      border-color: var(--color-primary);
    }

    & .count {
      margin-inline-start: auto;
      border-radius: 100%;
      padding: 0.25rem;
      background: rgb(255 255 255 / 58%);
    }
  }
}

.pyui_journeytabs-status-tab-content {
  width: 100%;
  overflow: auto;

  .percentile.options{
    display: flex;
    align-items: center;
    margin: 1rem 0rem;
    gap: 1rem;

    h3{
      width: 20%;
    }

    .select_options{
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }

  .notifications.subsection{
    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .options{
      margin-left: 0.75rem;
      display: flex;
      align-items: center;
      
      h3{
        width: 25%;
      }
      .select_options{
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }
  }
}


.pyui_error {
  &-wrapper {
    block-size: 100%;
    inline-size: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    background-image: linear-gradient(to bottom, #dfe9f3 0%, white 100%);
    padding-block-start: 4rem;
  }
  &-msg-title {
    text-align: center;
    font-size: 2rem;
  }
  &-msg {
    font-size: 1.15rem;
  }
}

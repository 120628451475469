// * {
//   font-family: "Poppins";
// }

/* @import url("https://cdn.jsdelivr.net/npm/bootstrap@5.2.2/dist/css/bootstrap.min.css"); */

// .dynamic-form {
//   /* box-shadow: 2px 6px 8px grey; */
//   margin-bottom: 5px;
//   padding-top: 5px;
//   padding-bottom: 5px;
//   /* margin: 0px 2px; */
//   max-width: 620px;
//   /* overflow: auto; */
// }

// .dynamic-form .form-group {
//   padding: 0.5em;
//   margin-right: 20px;
//   margin-left: 30px;
//   display: flex;
//   flex-wrap: wrap;
//   flex-direction: column;
//   justify-content: space-between;
// }

// .dynamic-form .form-label {
//   text-align: left;
//   color: #2e86c1;
//   font-size: 1rem;
//   white-space: pre-wrap;
// }

// .dynamic-form .form-input {
//   margin-top: 5px;
//   line-height: 1.5em;
//   /* border-color: #8a2be2; */
//   border: 1px solid rgba(145, 142, 142, 0.37);
//   border-radius: 5px;
//   padding: 5px;
// }

// .dynamic-form .form-input:focus {
//   outline: 1px solid #8ed6fc;
//   border-color: #8ed6fc;
// }

// .dynamic-form select {
//   margin-top: 5px;
//   line-height: 1.5em;
// }

// .dynamic-form .form-group button {
//   padding: 0.5em;
// }

// .dynamic-form .form-group-radio {
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
//   /* justify-content: center; */
//   /* background-color:red; */
// }

// .form-group-radio label {
//   margin-right: 15px;
//   font-size: 15px;
// }

// .form-group-radio input {
//   margin-right: 5px;
// }

// .dynamic-form .form-group-checkbox {
//   display: flex;
//   flex-direction: row;
//   align-items: flex-start;
// }

// .form-group-checkbox label {
//   margin-right: 15px;
//   font-size: 15px;
// }

// .form-group-checkbox input {
//   margin-right: 5px;
// }

// .form-title {
//   text-align: left;
// }

// .form-actions {
//   display: flex;
//   justify-content: end;
//   align-items: center;
//   border-top: 1px solid black;
// }

// .form-actions .action-buttons {
//   margin: 10px 20px;
// }

// /* .form-actions #button-1 {
//   padding: 5px 10px;
//   border-radius: 4px;
//   border: 0;
//   font-weight: 600; */
// /* width: 80px; */
// /* line-height: 1.5em; */
// /* background-color: #8a2be2;
//   color: white;
//   border-radius: 4px; */
// /* border: 5px solid #8a2be2; */
// /* margin-right: 10px;
//   cursor: pointer;
//   text-align: center;
//   font-size: 14px;
//   text-transform: capitalize;
//   font-family: "Poppins";
//   font-weight: 600; */
// /* } */

// .form-actions #button-1:hover {
//   background-color: #8a2be2;
// }

// .form-actions #button-1:active {
//   transform: translateY(3px);
// }

// .form-actions #button-2 {
//   padding: 5px 10px;
//   border-radius: 4px;
//   border: 0;
//   font-weight: 600;
//   /* width: 80px; */
//   /* line-height: 1.5em; */
//   background-color: #8a2be2;
//   color: white;
//   border-radius: 4px;
//   /* border: 5px solid #8a2be2; */
//   /* margin: 10px; */
//   cursor: pointer;
//   text-align: center;
//   font-size: 14px;
//   text-transform: capitalize;
//   font-family: "Poppins";
//   font-weight: 600;
// }

// .form-actions #button-2:hover {
//   background-color: #8a2be2;
// }

// .form-actions #button-2:active {
//   transform: translateY(3px);
// }

// .form-actions #mark-disabled:disabled {
//   cursor: not-allowed;
//   /* width: 150px; */
//   padding: 5px 10px;
//   background-color: gainsboro;
//   /* margin: 10px; */
//   border-radius: 4px;
//   border: 0;
//   font-weight: 600;
//   /* width: 80px; */
//   /* line-height: 1.5em; */
//   color: white;
//   border-radius: 4px;
//   /* border: 5px solid #8a2be2; */
//   text-align: center;
//   font-size: 14px;
//   text-transform: capitalize;
//   font-family: "Poppins";
//   font-weight: 600;
//   opacity: 0.9;
// }

// /* .form-actions #lable-1 { */
// /* width: 150px; */
// /* padding: 5px 10px; */
// /* line-height: 1.5em; */
// /* background-color: #8a2be2; */
// /* color: white;
//   border-radius: 4px;
//   border: 0;
//   margin: 10px;
//   font-size: 14px;
//   margin-right: 20px;
//   text-transform: capitalize;
//   font-family: "Poppins", sans-serif;
//   font-weight: 600; */
// /* } */

// /* .form-actions #lable-1.disabled {
//   opacity: .7;
//   background-color: #ccc;
//   cursor: not-allowed;
// } */

#lable-1 i {
  padding-right: 15px;
}

.formloader-spinner {
  text-align: center;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  /* background-color: yellowgreen; */
  /* width: 50px; */
  /* z-index: 9999; */
}

.formloader-spinner p {
  display: flex;
  align-items: center;
  padding: 3px 5px;
  background-color: rgba(100, 100, 111, 0.02);
}

.modalloader-spinner {
  background-color: #f4f4f4;
  margin: 20% auto;
  width: 30%;
  /* animation-name: modalopen; */
  /* animation-duration: 0.5s; */
  z-index: 2;
}

// .modal {
//   /* display: none; */
//   position: fixed;
//   z-index: 1;
//   left: 0;
//   top: 0;
//   height: 100%;
//   width: 100%;
//   background-color: rgba(0, 0, 0, 0.5);
// }

// .modalContent {
//   background-color: #f4f4f4;
//   margin: 20% auto;
//   width: 30%;
//   min-height: 130px;
//   animation-name: modalopen;
//   animation-duration: 0.5s;
//   z-index: 2;
// }

// #modalloader-spinner p {
//   /* background-color: #f4f4f4; */
//   /* margin: 20% auto; */
//   /* width: 30%; */
//   /* height: 20px; */
//   padding-top: 1.2rem;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }

// .modalContenttitle {
//   background-color: #8a2be2;
//   padding: 7px 15px;
// }

// .modaltitle {
//   color: white;
//   font-size: 1.2rem;
//   font-weight: 500;
//   /* display: flex; */
//   /* align-items: center; */
// }

// .modaltitle i {
//   font-size: 1rem;
//   margin-right: 0.6rem;
//   color: inherit;
// }

// .modal-body {
//   padding: 10px 15px;
// }

// .modalUserScore {
/* text-align: center; */
// display: flex;
// align-items: center;
// padding: 5px 0px;
// font-size: 16px;
// font-weight: 500;
// }

.modalUserScore i {
  font-size: 20px;
  margin-right: 10px;
  color: inherit;
  /* border-color: #8a2be2; */
}

.modalUsergreeting {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
}

.modalUsergreeting p {
  text-align: center;
  font-weight: 500;
}

.modal-footer-greeting {
  margin-top: 1.2rem;

  display: flex;
  align-items: center;
  justify-content: right;
}

// .modal-footer {
//   padding: 5px 0px;
//   gap: 0.5rem;
//   display: flex;
//   align-items: center;
//   justify-content: right;
// }

// #mark-btn-1 {
//   margin-left: 10px;
//   padding: 5px 7px;
//   border: 0;
//   color: #fff;
//   cursor: pointer;
//   /* border-radius: 5px; */
//   font-weight: 500;
//   background-color: #8a2be2;
//   font-size: 12px;
// }

// #cancel-btn-1 {
//   margin-left: 10px;
//   padding: 5px 7px;
//   border: 0;
//   color: #fff;
//   cursor: pointer;
//   /* border-radius: 5px; */
//   font-weight: 500;
//   background-color: #8a2be2;
//   font-size: 12px;
// }

// .cancel-btn-2 {
//   margin-left: 10px;
//   /* margin-right: 15px; */
//   padding: 5px 7px;
//   border: 0;
//   color: #fff;
//   cursor: pointer;
//   /* border-radius: 5px; */
//   font-weight: 500;
//   background-color: #8a2be2;
//   font-size: 12px;
// }

// #mark-btn-1:active,
// #cancel-btn-2:active,
// #cancel-btn-1:active {
//   transform: translateY(3px);
// }

// /* #mark-btn-2:disabled {
//   cursor: not-allowed;
//   background-color: #ccc;
//   opacity: 0.7;
//   padding: 5px 7px;
//   border: 0;
// } */

// .closebtn {
//   color: #fff;
//   font-size: 20px;
//   float: right;
//   /* background-color: #8a2be2; */
// }

// .closebtn:hover,
// .closebtn:focus {
//   color: black;
//   text-decoration: none;
//   cursor: pointer;
// }

.marked-completed-msg {
  display: flex;
  margin-top: 10px;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
}

.marked-completed-msg i {
  border-radius: 50%;
  border: 1px solid green;
  color: green;
  padding: 5px;
  margin-right: 10px;
}

.modal-footer-msg {
  display: flex;
  justify-content: right;
  align-items: flex-end;
  margin-top: 0.5rem;
}

// .primary-button {
//   border: none;
//   padding: 0.25rem 0.5rem;
//   border-radius: 3px;
//   background-color: var(--color-secondary);
//   color: white;
//   cursor: pointer;
//   font-size: 0.875rem;
//   font-weight: 500;
//   transition: all 0.4s ease-in-out;
//   margin-left: 0.875rem;
// }

// .primary-button:disabled {
//   background-color: #ccc;
//   pointer-events: none;
// }

// .primary-button:disabled:hover {
//   background-color: #ccc;
// }

// .primary-button:hover {
//   background-color: rgb(62 64 149 / 78%);
//   transition: all 0.2s ease-in-out;
// }

// .completed-button {
//   border: none;
//   padding: 0.25rem 0.5rem;
//   border-radius: 3px;
//   background-color: #059862;
//   color: white;
//   cursor: pointer;
//   font-size: 0.875rem;
//   font-weight: 500;
//   transition: all 0.4s ease-in-out;
//   margin-left: 0.5rem;
// }

// .completed-button:hover {
//   background-color: #4caf50;
//   transition: all 0.2s ease-in-out;
// }

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

.container-eval {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 15rem));
  gap: 1rem;
  justify-content: center;
  // row-gap: 20px;
  /* background-color: #d8f2f6; */
  /* padding: 0px 15px; */
  /* height: 800px; */
}

.main .main-link {
  display: flex;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  height: 100%;
  width: 100%;
  text-transform: none;
  color: black;
  border: none;
  // justify-content: center;
  padding: 1rem;
  // height: 150px;
  // width: 640px;
  /* box-shadow: rgba(0, 0, 0, 0.07) 0px 10px 50px; */
  box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
  /* transition: ease-in-out 0.3s; */
  transition: ease-in-out 0.3s;
}

.main .main-link:hover {
  box-shadow: rgba(149, 157, 165, 0.9) 0px 8px 24px;
  background-color: rgba(255, 255, 255, 0.1);
}

.main .main-link:disabled {
  cursor: not-allowed;
  box-shadow: rgba(149, 157, 165, 0.4) 0px 8px 24px;
  z-index: 2;
  opacity: 0.6;
}

.main .main-link:disabled img {
  opacity: 0.6;
}

.info-eval {
  display: flex;
  align-items: center;
  align-self: flex-start;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  /* font-size: large; */
}

.info-eval .data {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  gap: 0.25rem;
  /* margin-top: 7px;
  margin-bottom: 7px; */
  // line-height: 150%;
  /* padding: 3px 5px; */
  /* border: 1px solid black;
  border-radius: 50px; */
  /* background-color: #9966cc; */
}

.info-eval .title-eval {
  margin-top: 0px;
  // margin-bottom: 5px;
  font-weight: 600;
  font-size: 1rem;
}

// .data .status {
//   border: 1px solid black;
//   border-radius: 50px;
//   padding: 0 0.25rem;
// }

// .info .descp {
//   font-size: 0.75rem;
//   line-height: 150%;
//   margin-top: 7px;
//   text-align: left;
// }

img {
  width: 70px;
  height: 70px;
  /* justify-self: center; */
}

#mainimg-eval {
  width: 40px;
  height: 40px;
  background-color: #9966cc;
  border-radius: 50%;
  margin-right: 16px;
  align-self: center;
  /* margin-left: 15px; */
}

#chevright {
  width: 70px;
  height: 70px;
  background-color: transparent;
  border: none;
  width: 30px;
  height: 30px;
}

/* Easy */
.easy {
  border: 1px solid #29ab87;
  padding: 0 0.25rem;
  border-radius: 50px;
  color: #29ab87;
  background-color: #d0f0c0;
}

.medium {
  border: 1px solid #ff6700;
  background-color: #ffe5b4;
  border-radius: 50px;
  color: #ff6700;
  padding: 0 0.25rem;
}

.hard {
  border: 1px solid #800000;
  background-color: #fbceb1;
  border-radius: 50px;
  padding: 0 0.25rem;
  color: #800000;
}

.paginationbtn {
  margin: 20px 10px;
  width: 100%;

  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.paginationbtn a {
  padding: 0.25rem;
  font-size: 0.75rem;
  color: var(--color-text-default);
  cursor: pointer;
  border-radius: 2px;
  border: 1px solid #eee;
  min-width: 1.5rem;
  display: block;
  text-align: center;
  // background-color: #f0f8ff;
}

.paginationActive a {
  // color: #f0f8ff;
  background-color: #eee;
  color: var(--color-accent);
}

.disabledbtn a {
  cursor: not-allowed;
  opacity: 0.5;
}

.greet-main-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.eval-result {
  &.success {
    background-color: hsl(154deg 70% 88%);
    color: hsl(155deg 100% 25%);
    border-color: hsl(154deg 71% 83%);
  }

  &.error {
    background-color: hsl(350deg 78% 91%);
    color: hsl(349deg 61% 45%);
    border-color: hsl(349deg 79% 87%);
  }

  &.information {
    background-color: hsl(198deg 100% 92%);
    color: hsl(198deg 73% 46%);
    border-color: hsl(198deg 97% 87%);
  }
}

.pyui-evalpanel {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pyui-display {
  margin-left: 0.5rem;
}

.eval-questions {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  gap: 1rem;
 // background: rgb(204 204 204 / 25%);
}

.pyui-evalpanel-text {
  text-align: center;
  font-size: 1.25rem;
  color: rgb(255, 107, 107);
}

.eval-run-btn-warning{
  border: none;
  padding-top: inherit;
  // padding-left: 4rem;
  // border-radius: 3px;
  display: flex;
  // gap: 0.25rem;

  color: white;
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: 500;
  transition: all 0.4s ease-in-out;
  // background-color: hsl(51, 100%, 92%);
    color: var(--color-primary);
    // border-color: var(--color-primary);
}
.eval-run-btn-warning label{
  padding-top: inherit;
}
.run-test-count{
  color:  var(--color-accent);
  // font-weight: 500;
}


// Modal

.pyui_modal {
    position: fixed;
    // position: relative;
    z-index: 1000;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  
    &-content {
      background-color: #fff;
      box-shadow: 0 0.1875rem 0.1875rem 0 rgba(14, 34, 56, 0.07);
      margin: 20% auto;
      width: 24rem;
      max-width: 90%;
      min-height: 130px;
      animation-name: modalopen;
      animation-duration: 0.5s;
      z-index: 2;
      border-radius: 5px;
    }
  
    // header
    &-header {
      background-color: #4199ce;
      padding: 0.5rem 1rem;
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      border-top: 2px solid hsl(202.55deg 66.36% 81.71% / 46%);
      display: flex;
      justify-content: space-between;
  
      &-title {
        color: #fff;
        font-size: 1.2rem;
        font-weight: 400;
  
        i {
          font-size: 1rem;
          margin-right: 0.6rem;
          color: #fff;
        }
      }
  
      .closebtn {
        color: #fff;
        cursor: pointer;
      }
    }
  
    // modal body
    &-body {
      padding: 1.25rem 1.25rem 0.5rem 1.25rem;
  
      &-container {
        padding-bottom: 1rem;
        font-size: 0.875rem;
  
        .correct-answers {
          font-size: 1.15rem;
  
          >span {
            color: #aaa;
          }
        }
  
        .correct-answers-count {
          font-size: 1.75rem;
  
          &.pass {
            color: hsl(155deg 100% 25%);
          }
  
          &.fail {
            color: hsl(349deg 61% 45%);
          }
        }
  
        .total-questions {
          font-size: 1.75rem;
          color: var(--color-text-default);
        }
  
        .min-correct-answers {
          margin-block-start: 0.5rem;
        }
      }
    }
  }
  
  #modalloader-spinner p {
    /* background-color: #f4f4f4; */
    /* margin: 20% auto; */
    /* width: 30%; */
    /* height: 20px; */
    padding-top: 1.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modalUserScore {
    /* text-align: center; */
    display: flex;
    align-items: center;
    padding: 5px 0px;
    font-size: 1rem;
    // font-weight: 500;
  }
  
  .modalUserScore i {
    font-size: 20px;
    margin-right: 10px;
    color: inherit;
    /* border-color: #8a2be2; */
  }
  
  .modal-footer {
    padding: 5px 0px;
    gap: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: right;
  }
  
  @keyframes modalopen {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
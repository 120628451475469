.assessdatepicker{
    z-index: 1030;
    border-color: black;
    font-family: inherit;
    font-size: inherit;
    background: #fbfbfb;
    border-radius: 8px;
    box-shadow: 0 .5rem 1rem rgba(var(--bs-body-color-rgb), .15);
    width: auto;

}

.li-dateselector:hover{
    background-color: antiquewhite;
}

.loader {
    border: 7px solid #f3f3f3; /* Light grey */
    border-top: 7px solid #f11775; /* Blue */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1.2s linear infinite;
    position: fixed;    
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10000; 
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0); /* Fully transparent */
    z-index: 9999; /* Ensure the overlay is on top of everything */
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
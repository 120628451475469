.manage-users-main {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  gap: 1rem;

  .heading {
    display: grid;
    grid-template-columns: 3.25rem auto;

    h4 {
      font-size: 1.5rem;
      text-align: center;
    }
  }


  .action {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
  }

}
.archiverow {
  .archiverow_table {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .archiverow_details_actions {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;


      .archiverow_actions {
        margin: 0rem 2.25rem;

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
          border-radius: 5px;
          outline: none;
          cursor: pointer;
          padding: 0.5rem 0.7rem;
        }

        .unarchive {
          background-color: var(--color-secondary);
          border-color: var(--color-secondary);

          &:hover {
            background-color: var(--color-secondary-op-80);
            //  transition: all 0.2s ease-in-out;
          }

          &:disabled {
            pointer-events: none;
            opacity: 0.4;
          }
        }
      }

    }
  }
}